export enum Environment {
  LOCAL = "local",
  DEVELOPMENT = "dev",
  TEST = "test",
  STAGING = "staging",
  PRODUCTION = "production",
  NOT_WELL_DEFINED = "not-well-defined",
}

export function getEnvironment(): Environment {
  const nodeEnv: string | undefined = process.env.CONFIG_ENVIRONMENT ?? process.env.NODE_ENV;

  if (nodeEnv === (Environment.LOCAL as string)) {
    return Environment.LOCAL;
  }
  if (nodeEnv === (Environment.DEVELOPMENT as string) || nodeEnv === "development") {
    return Environment.DEVELOPMENT;
  }
  if (nodeEnv === (Environment.TEST as string)) {
    return Environment.TEST;
  }
  if (nodeEnv === (Environment.STAGING as string)) {
    return Environment.STAGING;
  }
  if (nodeEnv === (Environment.PRODUCTION as string) || nodeEnv === "prod") {
    return Environment.PRODUCTION;
  }
  throw new Error(`Unknown environment: ${nodeEnv}`);
}

export function isLocalEnvironment(): boolean {
  return getEnvironment() === Environment.LOCAL;
}
